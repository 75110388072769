<template>
  <div>
    <b-card
      no-body
      class="mb-0"
    >
      <titulo-card-com-voltar
        voltar="obras"
        titulo="Lista de Registro da Obra"
      />
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="4"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Exibição</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />

          </b-col>
          <b-col
            cols="4"
            md="4"
          >
            <select-com-pesquisa
              ref="selectStatus"
              :itens-para-edicao="[]"
              :multiple="false"
              placeholder="Filtre por status"
              valor-name-select="nome"
              url="/status-registros"
              @selecionados="tratarEventoFiltroStatus"
            />

          </b-col>
          <!-- Search -->
          <b-col
            cols="6"
            md="4"
          >
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Pesquisar..."
              />

            </div>
          </b-col>
        </b-row>

      </div>

      <b-table
        ref="refUserListTable"
        class="position-relative font-size-9"
        :items="fetchDados"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="Nenhum resultado encontrado"
        :sort-desc.sync="isSortDirDesc"
        :busy="isLoad"
        striped
      >
        <template #table-busy>
          <div class="text-center text-danger my-2">
            <b-spinner class="align-middle" />
            <strong> Carregando...</strong>
          </div>
        </template>
        <template #cell(descricao)="data">
          <div>
            {{ data.item.sub_tipo.nome }}
          </div>
          <div
            v-if="data.item.descricao"
            class="font-size-8"
          >
            {{ data.item.descricao }}
          </div>

        </template>

        <template #cell(data)="data">
          {{ mascaraDataBr(data.item.created_at) }}

        </template>
        <template #cell(sub_tipo.tipo)="data">

          <div
            class="badge"
            :class="{'badge-primary': data.item.sub_tipo.tipo.nome === 'Qualidade', 'badge-danger': data.item.sub_tipo.tipo.nome !== 'Qualidade'}"
          >
            {{ data.item.sub_tipo.tipo.nome }}

          </div>
        </template>
        <!-- Column: Nome e Imagem -->
        <template #cell(registrado_por)="data">
          <div class="d-flex justify-content-center">
            <b-media
              vertical-align="center"
            >
              <template #aside>
                <b-avatar
                  v-b-tooltip:top="data.item.registrado_por.name"
                  size="2.5em"
                  :src="data.item.registrado_por.imagem"
                  :text="avatarText(data.item.registrado_por.name)"
                  :variant="'secondary'"
                  class="avatar-with-shadow cursor-default"
                />
              </template>

            </b-media>

          </div>

        </template>

        <!-- Column: Actions -->
        <template #cell(ações)="data">
          <b-dropdown
            v-if="$can('atualizar', 'registros')"
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >

            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'registro-detalhes', params: { idObra:idObra, idRegistro: data.item.id } }">

              <feather-icon
                icon="SlidersIcon"
                size="16"
                class="mr-1"
              />
              Gerenciar
            </b-dropdown-item>

            <b-dropdown-item
              :to="{ name: 'registro-gerar-detalhamento', params: { idObra:idObra, idRegistro: data.item.id } }"
            >

              <feather-icon
                icon="PrinterIcon"
                size="16"
                class="mr-1"
              />
              Detalhamento
            </b-dropdown-item>
          </b-dropdown>

        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Exibindo {{ dataMeta.from }} a {{ dataMeta.to }} dos {{ dataMeta.of }} resultados
              encontrados</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalItens"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
      <modalConfirmacao
        id="modal-confirmacao"
        titulo="Excluir Item"
        :item-id="dadosItem.id"
        :item-nome="dadosItem.nome"
        @itemConfirmado="excluirItem"
        @click="modalExcluir(data.item)"
      />
    </b-card>
  </div>
</template>

<script>
import { avatarText, mascaraDataBr } from '@core/utils/filter'
import {
  BAvatar,
  BButton,
  BCard,
  BCol,
  BDropdown,
  BDropdownItem,
  BFormInput,
  BMedia,
  BPagination,
  BRow,
  BSpinner,
  BTable,
  VBTooltip,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import modalConfirmacao from '@/views/components/componentesPensou/ModalConfirmacao.vue'
import store from '@/store'
import vSelect from 'vue-select'

import router from '@/router'
import SelectComPesquisa from '@/views/components/componentesPensou/SelectComPesquisa.vue'
import TituloCardComVoltar from '@/views/components/componentesPensou/TituloCardComVoltar.vue'
import obraStoreModule from '../obraStoreModule'
import useItemList from './useItemList'

export default {
  components: {
    TituloCardComVoltar,
    SelectComPesquisa,
    modalConfirmacao,
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BDropdown,
    BDropdownItem,
    BPagination,
    BSpinner,
    vSelect,

  },
  directives: {
    'b-tooltip': VBTooltip,
  },

  setup() {
    const OBRAS_APP_STORE_MODULE_NAME = 'app-obras'
    const idObra = router.currentRoute.params.id

    // Register module
    if (!store.hasModule(OBRAS_APP_STORE_MODULE_NAME)) store.registerModule(OBRAS_APP_STORE_MODULE_NAME, obraStoreModule)

    // UnRegister on leave
    // onUnmounted(() => {
    //   if (store.hasModule(OBRAS_APP_STORE_MODULE_NAME)) store.unregisterModule(OBRAS_APP_STORE_MODULE_NAME)
    // })

    const listaUsuarios = ref([])

    const {
      fetchDados,
      fetchUsuarios,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      deleteItem,
      statusFilter,
    } = useItemList()

    store.dispatch('app-obras/fetchUsuarios')
      .then(response => {
        listaUsuarios.value = response.data
      })

    return {

      // Sidebar

      deleteItem,
      fetchDados,
      listaUsuarios,
      tableColumns,
      perPage,
      currentPage,
      totalItens,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refUserListTable,
      refetchData,
      fetchUsuarios,
      avatarText,
      mascaraDataBr,
      statusFilter,
      idObra,
    }
  },
  data() {
    return {

      dadosItem: {
        id: '',
        nome: '',
      },
    }
  },
  computed: {
    isLoad() {
      return this.$store.state['app-obras'].load
    },
  },

  methods: {

    modalExcluir(item) {
      this.dadosItem.nome = item.sub_tipo.nome
      this.dadosItem.id = item.uuid
      this.$root.$emit('bv::show::modal', 'modal-confirmacao')
    },
    excluirItem(dados) {
      this.deleteItem(dados)
    },
    tratarEventoFiltroStatus(itens) {
      this.statusFilter = itens && itens.id
    },
  },

}
</script>

<style lang="scss" scoped>
@import '@core/scss/vue/libs/vue-select.scss';

.per-page-selector {
  width: 90px;
}
</style>
